// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: -50px 0 0 0;
			padding: 10px 0 15px 0;
			border-radius: 5px;
			background: $green-dark;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				padding-left: 10px;
				padding-right: 10px;
			}

			// heading
			.heading {
				margin-bottom: 5px;
				color: #fff;
				font-size: 18px;
				font-weight: 600;
			}
		}
	}
}
