// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
&.home {
	.card,
	.default-card {
		.card-subtitle {
			display: none;
		}
	}
}
.card,
.default-card {
	border-radius: 10px;
	border: 0;
	box-shadow: $shadow;
	min-width: 340px;
	position: relative;
	overflow: visible;
	transition: 0.35s ease;
	.card-body {
		padding: 3rem 2.2rem;
		.card-title {
			font-size: $h3-font-size;
			font-family: $font-family-primary;
		}
		.card-subtitle {
			font-size: $h6-font-size !important;
			font-family: $font-family-secondary;
			text-transform: uppercase;
			font-weight: 200;
		}
	}
	.card-image {
		border-radius: 10px;
	}
	&:hover {
		transform: scale(1.025) translateY(-2px);
		transition: 0.35s ease;
		box-shadow: $shadow-dark;
	}
}

// container boxed
.container.boxed,
boxed {
	max-width: 70vw;
	@include media-breakpoint-down(md) {
		max-width: 95vw;
	}
}
