// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}

	&.bg-dark {
		background: linear-gradient(to right, $green, $green-dark);
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// grid section
.grid-section {
	margin: 6vh 0;
	.masonry {
		.card-columns {
			columns: 3;
			column-gap: 1rem;

			.item {
				height: max-content;
				&:nth-child(1) {
					max-width: calc(66% - 30px) !important;
					margin-right: 1rem;
					column-span: all;
					.card {
						box-shadow: none;
						background: none;
						cursor: default;
						pointer-events: none;

						.card-body {
							padding: 0 1rem;
						}

						.card-image {
							display: none;
						}
						.card-title {
							font-size: $h1-font-size;
							font-family: $font-family-secondary;
						}
						.card-subtitle {
							display: block !important;
							color: $green;
							font-size: $h5-font-size;
							font-family: $font-family-primary;
							font-weight: 700;
						}
						&:hover {
							transform: none !important;
						}
					}
				}
				&:nth-child(4) {
					.card {
						margin-top: 6vh !important;
					}
				}
			}
			@include media-breakpoint-down(lg) {
				columns: 2;
				.item {
					display: inline-block !important;
					width: 100% !important;
					transform: none !important;

					.card-title {
						font-size: $h6-font-size;
					}
					.card-body {
						padding: 1rem 0.75rem;
					}
					&:nth-child(1) {
						width: 100% !important;
						min-width: 100% !important;
						display: block !important;
						.card-body {
							padding: unset !important;
						}
						.card-title {
							font-size: 30px !important;
						}
					}
					.card,
					.card.icon {
						min-width: unset !important;
					}
					&:nth-child(4) {
						.card {
							margin-top: auto !important;
						}
					}
				}
			}
		}
	}
}

// nieuwsbrief section
.hero-section {
	padding: 9vh 0 !important;
	margin: 12vh 0 !important;
	.description {
		max-width: 40%;

		@include media-breakpoint-down(md) {
			max-width: unset;
		}
	}
}

// omgeving-section
.omgeving-section {
	margin: 18vh 0 8vh 0;
	.intro,
	.outro {
		margin: 8vh auto;
		padding: 6vh 0;

		.container-holder {
			max-width: 80%;
			margin: 0 auto;
			text-align: center;
			@include media-breakpoint-down(md) {
				max-width: unset;
			}
		}

		.title {
			line-height: $h2-font-size;
		}

		.subtitle {
			font-family: $font-family-primary;
			color: $green;
		}
		.description {
			margin-top: 3vh;
			.btn {
				margin: 0.5rem;
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin: 6vh 0;
	}
}

// accommodaties-section
.accommodaties-section {
	.intro,
	.outro {
		margin: 8vh auto;
		.container-holder {
			max-width: 80%;
			margin: 0 auto;
			text-align: center;
		}

		.title {
			line-height: $h2-font-size;
		}

		.subtitle {
			font-family: $font-family-primary;
		}
	}
	.accommodaties-container {
		margin-bottom: 6vh;
		.acco-grid {
			.grid-items {
				.item {
					flex-basis: 50%;
					max-width: 50%;
					border-radius: 10px;
					.card {
						position: relative;
						border-radius: 10px;
						.card-image {
							position: relative;
							border-radius: 10px;
							&::after {
								content: "";
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								background: rgba(#000, 0.05);
								transition: 1s ease;
							}
						}
						.card-body {
							position: absolute !important;
							width: 100%;
							height: 100%;
							padding: 0;
							.card-caption {
								padding: 0;
								display: flex;
								justify-content: center;
								align-items: center;
								.card-title {
									font-size: $h1-font-size;
									color: $white;
									text-shadow: 4px #000;
									max-width: 80%;
									text-align: center;
								}
								.card-description {
									display: none;
								}
							}
						}
						&:hover {
							.card-image::after {
								backdrop-filter: blur(1px);
								transition: 1s ease;
							}
						}
					}
					@include media-breakpoint-down(md) {
						flex-basis: 100%;
						max-width: 100%;
						.card-title {
							font-size: $h2-font-size !important;
						}
					}
				}
			}
		}
	}
}

// intro section
.intro-section {
	margin: 12vh 0;
	.container-one-column {
		.container-holder {
			max-width: 80%;
			margin: 0 auto;
			text-align: center;
		}
		.title {
			line-height: $h2-font-size;
		}
		.subtitle {
			color: $green;
			font-family: $font-family-primary;
		}
		.description {
			margin: 3vh 0;
		}
		ul.usps {
			display: flex !important;
			flex-flow: row nowrap;
			justify-content: space-between;
			li {
				display: flex;
				flex-flow: column;
				flex-basis: 33%;
				max-width: 33%;
				i {
					color: $red;
					font-size: 36px;
					margin: 5px;
				}
				.caption {
					display: flex;
					flex-flow: column;
					.list-item-title {
						font-weight: 700;
					}
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: column;
				gap: 3vh;
				li {
					flex-basis: 100%;
					max-width: 100%;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		margin: 6vh 0;
		.container-holder {
			max-width: initial;
		}
	}
}
// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			// max-width: 80%;
			// margin: 0 auto;
			// text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-8vh + 30px);
	margin-bottom: calc(-8vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
