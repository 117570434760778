// footer
.footer {
	margin: 3vh 0;
	.footer-links {
		margin: 9vh 0;
		.footer-text,
		.footer-list,
		.footer-links {
			h5 {
				font-family: $font-family-primary !important;
				font-size: 16px;
				font-weight: 700;
			}
		}
		a,
		a span.list-item-title {
			color: $green !important;
			font-size: 14px;
		}
		.container .container-holder {
			display: flex;
			justify-content: flex-start;
			.column {
				display: flex;
				align-items: center;
			}
		}
		@include media-breakpoint-down(md) {
			.container {
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				.column {
					flex-basis: 50%;
					max-width: 50%;
					align-items: flex-start!important;
					&:nth-child(1) {
						flex-basis: 100%;
						max-width: 100%;
						padding: 6vh 1.5rem;
					}
				}
			}
		}
	}
	.footer-socket {
		.list {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 1rem;
			a,
			span {
				color: $green-dark !important;
				font-size: 14px;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 7vh;
		}
	}
}
