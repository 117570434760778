// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	max-height: 540px;
	border-radius: 10px;
	.owl-caption,
	.owl-nav,
	.owl-dots {
		display: none;
	}
}
.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;

	@include media-breakpoint-up(sm) {
		height: 75vh;
	}
}

.omgeving-section,
.omgeving-section {
	.owl-carousel .owl-item,
	.owl-carousel .item {
		height: 60vh;
		border-radius: 10px;
		min-height: 300px;
		@include media-breakpoint-down(md) {
			height: 45vh;
		}
	}
	.owl-carousel {
		box-shadow: $shadow;
		border-radius: 10px;
		.item {
			padding: 0;
		}
		.owl-dots,
		.owl-btn,
		.owl-description,
		.owl-subtitle {
			display: none !important;
		}
		.column.default {
			padding-left: 0 !important;
		}
		.owl-caption-holder,
		.owl-container {
			height: 100%;
			width: 100%;
			padding-right: 0 !important;
			padding-left: 0 !important;
		}
		.owl-stage-outer {
			height: calc(100% + 70px);
		}
		.owl-caption {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			padding: 0;
			position: absolute;
			left: 0;
		}
		.owl-title {
			background: $green-dark;
			margin-bottom: 0 !important;
			position: absolute;
			bottom: 0;
			left: 50%;
			z-index: 1000;
			transform: translate(-50%, 50%);
			width: 70%;
			height: 70px;
			font-family: $font-family-primary;
			font-weight: 700;
			font-size: $h6-font-size !important;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			@include media-breakpoint-down(md) {
				font-size: 12px !important;
				padding: 0 1rem;
			}
		}
		.owl-nav {
			.owl-prev,
			.owl-next {
				top: 100%;
				margin: auto 0.5rem;
				i::before {
					font-size: 16px;
				}
				i {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				@include media-breakpoint-down(md) {
					margin: 0 !important;
					i {
						color: $green-dark;
						background: $white;
						aspect-ratio: 1/1;
						border-radius: 50%;
					}
				}
			}
			.owl-prev {
				left: 15%;
				i::before {
					content: "\f060";
					font-family: $font-awesome;
				}
				@include media-breakpoint-down(md) {
					left: 0;
				}
			}
			.owl-next {
				right: 15%;
				i::before {
					content: "\f061";
					font-family: $font-awesome;
				}
				@include media-breakpoint-down(md) {
					right: 0;
				}
			}
		}
	}
}

.video-eyecatcher,
.video-container,
.video-background {
	// min-height: 75vh;
	.item {
		min-height: 75vh !important;
		border-radius: 10px;
	}
	.video-background {
		border-radius: 10px;
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%; // 16:9 aspect ratio (9 / 16 * 100% = 56.25%)
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	@include media-breakpoint-down(md) {
		min-height: 45vh;
		.video-background {
			padding-bottom: 113.58% !important;
		}
	}
	&.small {
		min-height: unset !important;
		max-height: 46vh !important;

		.video-background,
		iframe {
			max-height: 45vh !important;
			min-height: unset !important;
		}
		.video-background {
			padding-bottom: 28.125% !important;
			overflow: hidden;
			iframe {
				border-radius: 10px;
			}
		}
	}
}
