// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $body-bg;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow-dark;
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					justify-content: space-between;
					z-index: 998;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		z-index: 1000;
		order: 1;
		.navbar-toggler {
			position: fixed;
			top: 2.5vh;
			right: 4.5vw;
			background: $body-bg;
			border-radius: 50%;
			aspect-ratio: 1/1;
			transition: 0.35s ease;
			transition-delay: 0.5s;
			i {
				color: $green-dark;
			}
			&[aria-expanded="true"] {
				background: $red;
				i::before {
					content: "\f00d";
					font-family: $font-awesome;
					color: $white;
					transition: 0.35s ease;
					transition-delay: 0.5s;
				}
			}
		}
	}

	// logo
	.logo {
		width: 237px;
		margin: 1.5rem 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(md) {
			width: 190px;
			z-index: 999;
		}
	}

	// menu
	.menu {
		.nav-item {
			.nav-link {
				color: $green-dark;
				font-size: 16px;
				font-weight: 600;
				&:hover {
					color: $red;
				}
			}
			&.active {
				.nav-link {
					color: $red;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			order: 2;
			position: fixed;
			right: -100vw;
			bottom: 0;
			top: 0;
			background: $body-bg;
			padding-top: 12vh;
			padding-left: 2rem;
			width: 100vw;
			height: 100vh !important;
			transition: 0.35s ease;
			&.show {
				right: 0vw;
				transition: 0.35s ease;
			}
			.dropdown-menu.show {
				position: static !important;
				transform: none !important;
				border: 0;
				background: none;
				margin: 0;
				margin: 0 1rem;
			}
			.nav-link {
				font-size: $h5-font-size !important;
				display: flex;
				align-items: center;
			}
		}

		@include media-breakpoint-up(xl) {
			margin-left: auto;
			margin-right: 0;
			display: flex;
			justify-content: flex-end;
			.navbar-nav {
				gap: 1rem;
			}
			.dropdown-menu.show {
				border: none;
				box-shadow: none;
				background: $body-bg;
				text-align: center;
				&::after,
				&::before {
					display: none;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 1.5rem;
		@include media-breakpoint-down(md) {
			margin-left: unset;
			z-index: 999;
		}
	}

	// Zoek en boek button
	div.floating-btn {
		position: fixed;
		right: 0;
		top: 40vh;
		a {
			@extend .btn, .btn-primary;
			writing-mode: vertical-lr;
			padding: 1.2rem 0.75rem !important;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			i {
				font-size: 22px;
				font-weight: 600;
				transition: 0.35s ease;
			}
			&:hover {
				background: rgba($red, 0.8);
				i {
					transform: scaleX(-1);
					transition: 0.35s ease;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			top: unset !important;
			bottom: 0;
			left: 0;

			a {
				writing-mode: initial;
				width: 100vw;
				justify-content: flex-end;
				padding-right: 2rem !important;
			}
		}
	}
	@include media-breakpoint-down(md) {
		position: relative;
	}
}
